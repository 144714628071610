html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100%;
}

#root{
  height: 100%;
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 7px;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  background: #5b5e64; 
}
 
/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: rgb(211, 211, 211); 
}

/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}

.height-max{
  height: 100%;
}

.full-line{
  width: max-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  cursor: pointer;
  border-radius: 5px;
}

button:hover{
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

input[type="radio"]{
  width: 20px;
  height: 20px;
}

.radioLabel{
  margin-top: 4px;
  position: absolute;
}

input[type="checkbox"]{
  width: 19px;
  height: 19px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-error{
  border-color: red !important;
}

.checkLabel{
  margin-top: 3px;
  position: absolute;
}

/*input[type="radio"]{
  display: none;
}

input[type="radio"]+label{
  background: url("icons/radio-no-selected.svg") left center no-repeat;
  background-size: 18px;
  padding: 3px;
  padding-left: 30px;
  width:21px;
  height:21px;
}

input[type="radio"]:checked+label{
  background: url("icons/radio-selected.svg") left center no-repeat;
  background-size: 18px;
  padding: 3px;
  padding-left: 30px;
}

input[type="checkbox"]{
  display: none;
}

input[type="checkbox"]+label{
  background: url("icons/checkbox-no-selected.svg") left center no-repeat;
  background-size: 18px;
  padding: 4px;
  padding-left: 30px;
  width:21px;
  height:21px;
}

input[type="checkbox"]:checked+label{
  background: url("icons/checkbox-selected.svg") left center no-repeat;
  background-size: 18px;
  padding: 4px;
  padding-left: 30px;
}*/

.field-popup-container-lb{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
}

.field-popup-container-input{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 3px;
  padding-left: 5px;
}

.field-popup-input-help{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 5px;
  font-size: 12px;
  color: gray;
}

.field-error{
  color: #EB9694 !important;
  margin-left: 3px;
  margin-top: 3px;
  font-size: 14px;
}

.field-popup-input-small{
  width: 200px;
  height: 25px;
  border: 1px silver solid;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.component-hidden{
  display: none;
}

.left-bar{
  position: relative;
  float: left;
  width: 60px;
  height: calc(100% - 20px);
  background-color: #4c4c4c;
}

.top-bar{
  position: relative;
  float: left;
  width: calc(100% - 60px);
  height: 70px;
  background-color: #404040;
}

.content-container{
  position: relative;
  float: left;
  width: calc(100% - 60px);
  height: calc(100% - 70px - 20px);
  background-color: #202124;
}

.content{
  position: relative;
  float: left;
  width: calc(100% - 50px);
  height: 100%;
  background-color: #ffffff;
  margin: 25px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.content-reduced{
  position: relative;
  float: left;
  width: calc(100% - 50px - 250px);
  height: 100%;
  background-color: #ffffff;
  margin: 25px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.bottom-bar{
  position: relative;
  float: left;
  width: 100%;
  height: 20px;
  background-color: #404040;
}

.breadcrump{
  position: relative;
  float: left;
  width: 60%;
  height: 15px;
  margin-top: 7px;
  margin-bottom: 5px;
  margin-left: 25px;
  font-size: 11px;
  color: #fcfcfc;
  font-weight: 400;
}

.breadcrump-actual{
  font-weight: bold;
  text-transform: uppercase;
}

.form-name-edit{
  position: relative;
  float: left;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  
}

.form-name-field{
  position: relative;
  float: left;
  color: #232459ff;
  border: none;
  width: calc(100% - 10px);
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  padding-left: 3px;
  padding-right: 3px;
  text-overflow: clip;
  margin-left: 1px;
}

.logo{
  position: relative;
  float: left;
  width: 25px;
  height: 25px;
  margin: 17px;
  margin-bottom: 5px;
  margin-top: 15px;
  background: url("icons/logo.svg") center no-repeat;
  border: none;
}

.logo-name{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 11px;
  margin-bottom: 20%;
  color: #e2e2e2;
  font-weight: 400;
}

.user-menu-icon{
  position: relative;
  float: right;
  width: 30px;
  height: 30px;
  margin-top: 20px;
  margin-right: 5px;
  background: url("icons/user-no-photo.svg") center no-repeat;
  background-size: 30px;
  border: none;
}

.user-menu-open-icon{
  position: relative;
  float: right;
  width: 20px;
  height: 20px;
  margin-top: 26px;
  margin-right: 9px;
  background: url("icons/open-down.svg") center no-repeat;
  background-size: 20px;
  border: none;
}

.popup-dialog-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  z-index: 5000;
}

.popup-dialog-content-container{
  margin: auto;
  margin-top: 2%;
  border: 1px solid #d8d8d8;
  background-color: #d8d8d8;
}

.popup-dialog-title{
  text-align: center;
  padding: 10px;
}

.popup-dialog-close-bt{
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin: 5px;
  margin-top: -29px;
  border: none;
  background: url("icons/close-form-bt.svg") center no-repeat;
  background-size: 20px 20px;
}

.popup-dialog-content{
  position: relative;
    float: left;
    width: 100%;
    max-height: 82vh;
    background-color: white;
    border: 1px solid #d8d8d8;
    margin-left: -1px;
    overflow: hidden;
    overflow-y: auto;
}

.popup-dialog-content-sub-container{
  float: left;
  width: 100%;
  margin: auto;
  border: 1px solid #d8d8d8;
  background-color: #d8d8d8;
  text-align: center;
  padding: 10px;
  padding-left: 0px;
}

.popup-temp-content-container{
  margin: auto;
  margin-top: 2%;
  background-color: #d8d8d8;
}

.popup-temp-content{
  position: relative;
    float: left;
    width: 100%;
    max-height: 82vh;
    background-color: white;
    margin-left: -1px;
    overflow: hidden;
    overflow-y: auto;
}

.session-format-edit{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  background-color: #f6f1ffff;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #5d37b9ff;
}

.session-format-one-column-edit{
  position: relative;
  float: left;
  border: 2px solid transparent;
  border-radius: 0%;
  width: 90px;
  background: url("icons/one-column.svg") center top no-repeat;
  background-size: 25px;
  margin-top: 10px;
  padding-top: 30px;
  font-size: 12px;
  color: #232459ff;
}

.session-format-two-columns-edit{
  position: relative;
  float: left;
  border: 2px solid transparent;
  border-radius: 0%;
  width: 90px;
  background: url("icons/two-columns.svg") center top no-repeat;
  background-size: 25px;
  margin-top: 10px;
  padding-top: 30px;
  font-size: 12px;
  color: #232459ff;
}

.session-format-mobile-edit{
  position: relative;
  float: left;
  border: 2px solid transparent;
  border-radius: 0%;
  width: 90px;
  background: url("icons/mobile.svg") center top no-repeat;
  background-size: 20px;
  margin-top: 10px;
  padding-top: 40px;
  font-size: 12px;
  color: #232459ff;
}

.add-more-fields-edit{
  padding: 10px;
  border: 1px silver solid;
  background-color: #7a66a9;
  color: #ffffff;
  margin-left: 6px;
  font-size: 14px;
}

.form-container{
  position: absolute;
  width: -webkit-fill-available;
  height: auto;
}

.form-title-container{
  margin: auto;
  width: 50%;
  height: 30px;
  border: 1px red solid;
}

.form-title{
  position: relative;
  float: left;
  margin: 5px;
}

.form-stack-container-show{
  position: absolute;
  width: -webkit-fill-available;
  height: auto;
  background: white;
  border: 1px red solid;
}

.form-stack-container-hidden{
  display: none;
}

.form-close-stack-bt{
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin: 5px;
  border: none;
  background: url("icons/close-form-bt.svg") right no-repeat white;
  background-size: 20px 20px;
}

.field-container{
  border: 1px silver solid;
  padding: 15px;
  border-radius: 3px;
  width: -webkit-fill-available;
  min-width: 300px;
  margin-bottom: 25px;
  height: 110px;
}

.field-drag-drop-text{
  float: left;
  color: gray;
  text-align: center;
  width: 70%;
}

.field-drag-drop-bt-container{
  float: left;
  text-align: center;
  width: 29%;
}

.field-container-table{
  display: table;
  width: -webkit-fill-available;
}

.field-drag-drop-bt{
  border: 1px silver solid;
  border-radius: 25px;
  height: 40px;
  width: 40px;
  font-size: 18px;
  font-weight: bold;
  background-color: lightgreen;
}

.field-drag-drop{
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  height: 110px;
}

.field-top-container{
  height: 30px;
}

.field-content-container{
  width: 100%;
}

.field-move-bt{
  float: right;
  background: url("icons/move-bt.svg") center no-repeat white;
  background-size: 18px 18px;
  width: 30px;
  height: 30px;
  border: none;
  margin-right: 8px;
}

.field-opt-bt{
  float: right;
  background: url("icons/opt-bt.svg") center no-repeat white;
  width: 30px;
  height: 30px;
  border: none;
  margin-right: 5px;
}

.field-text{
  width: -webkit-fill-available;
  margin-top: 10px;
  height: 40px;
  border: 1px silver solid;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.field-opt-text{
  width: -webkit-fill-available;
  margin-top: 10px;
  height: 40px;
  border: 1px silver solid;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.field-use-in-table-on{
  float: right;
  margin-top: 5px; 
  background: url("icons/toggle-on-bt.svg") right no-repeat;
  background-size: 25px 25px;
  padding-right: 30px;
  height: 30px;
  border: 1px solid transparent;
  font-weight: bold;
}

.field-use-in-table-off{
  float: right;
  margin-top: 5px; 
  background: url("icons/toggle-off-bt.svg") right no-repeat;
  background-size: 25px 25px;
  padding-right: 30px;
  height: 30px;
  border: 1px solid transparent;
  font-weight: bold;
}

.field-required-on{
  float: right;
  margin-top: 5px; 
  background: url("icons/toggle-on-bt.svg") right no-repeat;
  background-size: 25px 25px;
  padding-right: 30px;
  height: 30px;
  border: 1px solid transparent;
  font-weight: bold;
  margin-right: 6px;
}

.field-required-off{
  float: right;
  margin-top: 5px; 
  background: url("icons/toggle-off-bt.svg") right no-repeat;
  background-size: 25px 25px;
  padding-right: 30px;
  height: 30px;
  border: 1px solid transparent;
  font-weight: bold;
  margin-right: 6px;
}

.field-name{
  float: left;
  border: none;
  font-weight: bold;
  width: 70%;
  height: 30px;
}

.field-slot-one-column{
  margin: auto;
  width: 50%;
  height: 100%;

  border: 1px red solid;
}

.field-slot-one-cell{
  width: 99%;
  margin-right: 1%;
}

.field-slot-two-columns{
  margin: auto;
  width: 80%;
  height: 100%;

  border: 1px red solid;
}

.field-slot-two-cell{
  float: left;
  width: 47%;
  margin-right: 2%;
}

.field-slot-mobile{
  margin: auto;
  width: 50%;
  height: 100%;

  border: 1px red solid;
  border-radius: 15px;
}

.fields-scroll{
  height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px transparent solid;
}

.field-opt-container{
  position: absolute;
  left: 25%;
  height: 40%;
  width: calc(50% - 2px);
  min-width: 330px;

  background: #ffffff;
  border: 1px red solid;
}

.form-tool-bar{
  margin: auto;
  width: 50%;
}

.form-save-bt{
  padding: 10px;
  font-size: 14px;
}

.form-open-container{
  position: relative;
  float: right;
  height: 40px;
  /*width: 40px;*/
  margin-top: -42px;
  text-align: center;
}

.form-open-bt{
  height: 30px;
  border: 1px silver solid;
  border-radius: 5px;
  background: url("icons/open-form-bt.svg") 5px no-repeat;
  background-size: 20px 20px;
  padding-left: 30px;
  margin-right: 7px;
  margin-top: 5px;
}

.form-table-container{
  margin: auto;
  width: 50%;
}

.child-panel{
  position: absolute;
  top: 0px;
  width: 50%;
  height: 100%;
  background-color: red;
}

.popup-section-title{
  float: left;
  text-align: center;
  padding: 3px;
  background-color: #f1f1f1;
  width: 100%;
  margin-top: 3px;
  font-size: 15px;
}

.rep-field-container{
  float: left;
  width: 44%;
  margin: 15px;
  min-width: 300px;
}

.rep-field-container-bt{
  float: left;
  width: 15%;
  height: 55px;
  text-align: center;
}

.rep-field-add-bt{
  border: 1px silver solid;
  border-radius: 25px;
  height: 30px;
  width: 30px;
  font-size: 18px;
  font-weight: bold;
  background-color: lightgreen;
  margin-top: 18px;
}

.rep-field-container-content{
  float: left;
  width: 80%;
  height: 55px;
  padding: 5px;
  border: 1px solid #d8d8d8;
}

.rep-field-title{
  float: left;
  font-size: 14px;
}

.rep-field{
  float: left;
  width: -webkit-fill-available;
  height: 15px;
  padding: 5px;
  margin-top: 5px;
}

.rep-field-with-bt{
  float: left;
  width: 82%;
  height: 15px;
  padding: 5px;
  margin-top: 5px;
}

.rep-field-bt{
  float: right;
  width: 25px;
  height: 25px;
  margin-top: 6px;
  
  border: none;
  background: url("icons/open-form-bt.svg") center no-repeat;
  background-size: 20px 20px;
}

.panel-apps-container{
  margin: auto;
  margin-left: 18px;
  width: calc(100% - 15px);
  height: 100%;

  border: 1px transparent solid;
}

.panel-apps-scroll{
  height: calc(100% - 50px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-card-container{
  float: left;
  width: 80px;
  height: 105px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.app-card-name-container{
  display: table;
  float: left;
  height: 85px;
  width: 100%;
}

.app-card-name{
  display: table-cell;
  width: 98%;
  padding-right: 2%;
  padding-left: 2%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  overflow: hidden;
}

.app-card-new-lb{
  display: table-cell;
  width: 98%;
  padding-right: 2%;
  padding-left: 2%;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  vertical-align: middle;
  overflow: hidden;
}

.app-card-bt-container{
  display: table;
  width: 100%;
  height: 0px;
  text-align: center;
  vertical-align: middle;
}

.app-card-bt-wrap{
  display: table-cell;
  vertical-align: middle;
}

.app-card-play-bt{
  position: absolute;
  width: 80px;
  height: 105px;
  border: 1px #afafaf solid;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #2e2f31;
  background-color: transparent;
}

.properties-top-bar{
  position: absolute;
  width: 250px;
}

.close-properties-bt{
  position: relative;
  float: right;
  width: 16px;
  height: 16px;
  border: none;
  background: url("icons/close-properties.svg") center no-repeat;
  background-size: 16px;
  margin-top: 10px;
  margin-right: 11px;
}

.app-card-quick-tools{
  position: absolute;
  width: 80px;
  height: 25px;
  margin-top: 110px;
  text-align: center;
}

.app-card-edit-bt{
  position: relative;
  width: 22px;
  height: 22px;
  border: none;
  background: url("icons/edit_form_bt.svg") center no-repeat;
  background-size: 22px;
}

.app-card-color-bt{
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border: none;
  background: url("icons/change_color_bt.svg") center no-repeat;
  background-size: 20px;
}

.app-card-quick-options-bt{
  position: relative;
  float: right;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: none;
  background: url("icons/opt-bt.svg") center no-repeat;
  background-size: 3px;
}

.color-picker-container{
  float: left;
  border-radius: 8px;
  overflow: hidden;
  width: 196px;
}

.color-picker-bt{
  position: relative;
  float: left;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 0px;
}

.icon-picker-container{
  float: left;
  border-radius: 8px;
  overflow: hidden;
  width: 210px;
  height: 120px;
  overflow-y: auto;
  margin-top: 15px;
  margin-bottom: 25px;
}

.icon-picker-bt{
  position: relative;
  float: left;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  background-color: #404040;
}

.app-card-new-bt{
  position: relative;
  float: left;
  border: 2px solid transparent;
  border-radius: 0%;
  width: 60px;
  background: url("icons/app-new-bt3.svg") center top no-repeat;
  background-size: 20px;
  margin-top: 24px;
  padding-top: 25px;
  font-size: 12px;
  color: #fbfbfb;
}

.app-folder-new-bt{
  position: relative;
  float: left;
  border: 2px solid transparent;
  border-radius: 0%;
  width: 60px;
  background: url("icons/new-folder.svg") center top no-repeat;
  background-size: 20px;
  margin-top: 42px;
  padding-top: 25px;
  font-size: 12px;
  color: #fbfbfb;
}

.app-card-new-project-bt{
  position: relative;
  float: left;
  width: 145px;
  height: 40px;
  padding: 10px;
  padding-left: 35px;
  border: none;
  border-radius: 8px;  
  background: url("icons/app-new-bt-filled.svg") left top no-repeat;
  background-size: 20px;
  background-position: 18px center;
  background-color: #5b5e64;
  color: #ffffff;
  margin-left: 24px;
  margin-top: 16px;
  font-size: 12px;
}

.execution-mode-bt{
  position: relative;
  float: right;
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 8px;  
  background-position: 18px center;
  background-color: #5b5e64;
  color: #ffffff;
  margin-right: 30px;
  margin-top: 18px;
  font-size: 12px;
}

.edition-mode-bt{
  position: relative;
  float: right;
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 8px;
  background-position: 18px center;
  background-color: #5b5e64;
  color: #ffffff;
  margin-right: 14px;
  margin-top: 18px;
  font-size: 12px;
}

.active-mode{
  background-color: #3e7ce8;
}

.app-new-container{
  padding: 15px;
  border-radius: 3px;
  width: -webkit-fill-available;
  float: left;
  min-width: 300px;
}

.field-text-system{
  width: -webkit-fill-available;
  height: 34px;
  border: none;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background-color: #404040;
  color: #afafaf;
}

.buttons-form-container{
  width: 100%;
  margin-top: 15px;
}

.app-new-from-create-bt{
  float: left;
  padding: 10px;
  border: 1px silver solid;
  background-color: #7a66a9;
  color: #ffffff;
  margin-left: 3px;
  margin-bottom: 10px;
  font-size: 14px;
}

.panel-apps-title{
  float: left;
  width: 90%;
  height: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  background: url("icons/folder.svg") left top no-repeat;
  background-size: 20px;
  padding-left: 25px;
  padding-top: 3px;
  color: #2e2f31;
}

.snippet-dialog-section{
  position: absolute;
  background-color: #ffffff;
  padding: 5px;
  z-index: 1000;
  
  border: 1px silver solid;
}

.snippet-dialog-top{
  width: 100%;
}

.snippet-dialog-content{
  width: 100%;
}

.snippet-dialog-close-bt{
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin: 5px;
  margin-right: 0px;
  border: none;
  background: url("icons/close-form-bt.svg") center no-repeat;
  background-size: 20px 20px;
}

.snippet-field-name{
  float: left;
  border: none;
  font-weight: bold;
  height: 30px;
  margin-top: 5px;
}

.left-bar-bt-container{
  float: left;
}

.forms-relationship{
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}

.forms-relationship-item{
  float: left;
  width: 100%;
}

.forms-relationship-app-name{
  position: relative;
  float: left;
  font-weight: bold;
  margin-top: 5px;
  margin-left: 5px;
  width: 100%;
}

.forms-relationship-form-name{
  position: relative;
  float: left;
  padding-left: 9px;
  width: calc(100% - 15px);
  margin-top: 3px;
}

.forms-relationship-form-field-name{
  position: relative;
  float: left;
  padding-left: 10px;
  width: calc(100% - 25px);
  margin-bottom: 5px;
}

.forms-relationship-new-form{
  position: relative;
  float: left;
  border: 2px solid transparent;
  border-radius: 0%;
  width: 20px;
  height: 20px;
  background: url("icons/app-new-bt.svg") center no-repeat;
  background-size: 20px;
  margin-top: 5px;
  margin-left: 14px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #232459ff;
}

.forms-relationship-field-config-lb{
  margin: 5px;
}

.forms-relationship-field-container{
  height: 80px;
  overflow: hidden;
  overflow-y: auto;
}

.forms-relationship-field-example{
  height: 50px;
  overflow: hidden;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
}

.forms-relationship-field-example-lb{
  margin: 5px;
}

.forms-relationship-do-relation-bt{
  padding: 10px;
  border: 1px silver solid;
  background-color: #7a66a9;
  color: #ffffff;
  margin-left: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.alert-msg-inside{
  margin: 5px;
  width: calc(100% - 25px);
  text-align: center;
  border: 1px orange solid;
  border-radius: 5px;
  background-color: rgb(250, 250, 133);
  padding: 5px;
  font-size: 14px;
  color: rgb(46, 46, 46);
}

.success-msg-inside{
  margin: 5px;
  width: calc(100% - 25px);
  text-align: center;
  border: 1px rgb(60, 255, 0) solid;
  border-radius: 5px;
  background-color: rgb(133, 250, 178);
  padding: 5px;
  font-size: 14px;
  color: rgb(46, 46, 46);
}

.right-container{
  float: left;
  width: 250px;
  height: 100%;
  background-color: #2e2f31;
  margin-top: -27px;
  overflow-x: auto;
}

.props-app-edit-fields{
  float: left;
  width: calc(90% - 25px);
  height: 35px;
  margin-top: 25px;
  margin-left: 25px;
  color: #fbfbfb;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  background-color: #3e7ce8;
}

.props-title{
  float: left;
  width: 80%;
  margin-top: 25px;
  margin-left: 25px;
  color: #fbfbfb;
  font-size: 14px;
  font-weight: 500;
}

.props-field-large-container{
  float: left;
  width: calc(90% - 25px);
  margin-left: 25px;
  margin-top: 10px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons.md-light { 
  color: rgba(255, 255, 255, 0.9); 
}

.material-icons.light { 
  color: rgba(255, 255, 255, 1); 
}

.material-icons.md-dark { 
  /*color: rgba(0, 0, 0, 0.54);*/
  color: rgba(0, 0, 0, 1); 
}

.material-icons.md-36 { 
  font-size: 36px; 
}

.app-card-icon{
  position: absolute;
  width: 36px;
  height: 36px;
  margin-top: 15px;
  margin-left: 22px;
}